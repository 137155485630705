const requirementsList = () => import('@/view/requirementsList/requirementsList.vue')
const requirementsListOrder = () => import('@/view/requirementsListOrder/requirementsList.vue')
const addDemand = () => import('@/view/addDemand/addDemand.vue')
const addDemandOrder = () => import('@/view/addDemandOrder/addDemand.vue')
const venueList = () => import('@/view/venue/venueList.vue')
const venueDetails = () => import('@/view/venue/venueDetails/venueDetails.vue')
const map = () => import('@/view/venue/map.vue')
const supplierList = () => import('@/view/supplierList/supplierList.vue')
const quotedPriceDemandList = () => import('@/view/quotedPrice/quotedPriceDemandList/quotedPriceDemandList')
const quotedPriceDemandListOrder = () => import('@/view/quotedPriceOrder/quotedPriceDemandList/quotedPriceDemandList')
const quotedPriceDetails = () => import('@/view/quotedPrice/quotedPriceDetails/quotedPriceDetails')
const quotedPriceDetailsOrder = () => import('@/view/quotedPriceOrder/quotedPriceDetails/quotedPriceDetails')
const costList = () => import('@/view/cost/costList')
const lossList = () => import('@/view/loss/lossList')
const confirmCost = () => import('@/view/cost/confirmCost')
const confirmCostOrder = () => import('@/view/costOrder/confirmCost')
const costItemDetail = () => import('@/view/cost/components/costItemDetail')
const costItemDetailOrder = () => import('@/view/costOrder/components/costItemDetail')
const confirmLoss = () => import('@/view/loss/confirmLoss')
const confirmLossOrder = () => import('@/view/lossOrder/confirmLoss')
const supplementFile = () => import('@/view/supplementFile')
const projectProcess = () => import('@/view/projectProcess/projectProcess')
const orderList = () => import('@/view/orderList/orderList')
const supplierQuote = () => import('@/view/supplierQuote/supplierQuote')
const quoteSuccess = () => import('@/view/supplierQuote/quoteSuccess')
const otherSupplier = () => import('@/view/otherSupplier')
const routers = [
    {
        path: '/',
        redirect: "/requirementsList"
    },
    // 需求列表
    {
        path: '/requirementsList',
        name: 'requirementsList',
        component: requirementsList,
        backRouteName: 'ievent'
    },
    // 酒店添加需求
    {
        path: '/addDemand',
        name: 'addDemand',
        component: addDemand,
        backRouteName: 'ievent'
    },
    // 添加酒店列表
    {
        path: '/venueList',
        name: 'venueList',
        component: venueList,
        backRouteName: 'venueList'
    },
    // 酒店详情
    {
        path: '/venueDetails',
        name: 'venueDetails',
        component: venueDetails,
        backRouteName: 'venueDetails'

    },
    // 地图
    {
        path: '/map',
        name: 'map',
        component: map,
        backRouteName: 'map'

    },
    // 地图
    {
        path: '/supplierList',
        name: 'supplierList',
        component: supplierList,
        backRouteName: 'supplierList'

    },
    // 报价需求列表
    {
        path: '/quotedPriceDemandList',
        name: 'quotedPriceDemandList',
        component: quotedPriceDemandList
    },
    // 报价需求详情
    {
        path: '/quotedPriceDetails',
        name: 'quotedPriceDetails',
        component: quotedPriceDetails
    },
    // 结算需求列表
    {
        path: '/costList',
        name: 'costList',
        component: costList
    },
    // 结算需求列表
    {
        path: '/confirmCost',
        name: 'confirmCost',
        component: confirmCost
    },
    // 损失需求列表
    {
        path: '/lossList',
        name: 'lossList',
        component: lossList
    },
    // 损失需求列表
    {
        path: '/confirmLoss',
        name: 'confirmLoss',
        component: confirmLoss
    },
    // 损失需求列表
    {
        path: '/costItemDetail',
        name: 'costItemDetail',
        component: costItemDetail
    },
    {
        path: '/supplementFile',
        name: 'supplementFile',
        component: supplementFile
    },
    {
        path: '/projectProcess',
        name: 'projectProcess',
        component: projectProcess
    },
    {
        path: '/orderList',
        name: 'orderList',
        component: orderList
    },
    {
        path: '/requirementsListOrder',
        name: 'requirementsListOrder',
        component: requirementsListOrder
    },
    {
        path: '/addDemandOrder',
        name: 'addDemandOrder',
        component: addDemandOrder
    },
    {
        path: '/quotedPriceDemandListOrder',
        name: 'quotedPriceDemandListOrder',
        component: quotedPriceDemandListOrder
    },
    {
        path: '/quotedPriceDetailsOrder',
        name: 'quotedPriceDetailsOrder',
        component: quotedPriceDetailsOrder
    },
    {
        path: '/confirmCostOrder',
        name: 'confirmCostOrder',
        component: confirmCostOrder
    },
    {
        path: '/costItemDetailOrder',
        name: 'costItemDetailOrder',
        component: costItemDetailOrder
    },
    {
        path: '/confirmLossOrder',
        name: 'confirmLossOrder',
        component: confirmLossOrder
    },
    {
        path: '/supplierQuote',
        name: 'supplierQuote',
        component: supplierQuote
    },
    {
        path: '/quoteSuccess',
        name: 'quoteSuccess',
        component: quoteSuccess
    },
    // 上会人员&&飞机火车票明细
    {
        path: '/otherSupplier', // 上会人员&&飞机火车票明细(分情况展示)
        name: 'otherSupplier',
        component: otherSupplier
    }
]
export default routers
