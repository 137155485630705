import Vue from 'vue';

const androidBackBridge = {
	created() {
		if(!window.webkit && window.SMGeneral) {
			window.SMGeneral.setBackPressEnable(true);
			window.SMGeneral.setOnBackBressedListener("callBack");
		}
		window['callBack'] = () => {
			this.nativeBackJsBridge();
		}
	},
	methods: {
		nativeBackJsBridge() {
			let routeListeners = this.$SmartStorage.get('routeListeners');
			if(routeListeners.length > 0) {
				var key = routeListeners.pop();
				switch(key){
					default:
						this.$root.$eventHub.$emit(key);
				}
			}
		},
		//保存当前路由返回事件记录
		setRouteListeners(backMethod) {
			let routeListeners = this.$SmartStorage.get('routeListeners') || [];
			if(routeListeners && routeListeners.indexOf(backMethod) == -1) {
				routeListeners.push(backMethod);
			}
			this.$SmartStorage.set('routeListeners', routeListeners);
		},
		//返回上一页删除当前退回记录
		removeRouteListeners(backMethod) {
			let routeListeners = this.$SmartStorage.get('routeListeners') || [];
			routeListeners = routeListeners.filter((ele) => {
				return ele != backMethod
			});
			this.$SmartStorage.set('routeListeners', routeListeners);
		},
	}
}

Vue.mixin(androidBackBridge)

export default androidBackBridge;
