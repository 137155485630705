import {httpService} from './httpService'
let publicFun = {
    getFullDate(date, format='yyyy-MM-dd') {
        function formatNum(x) {
            return x > 9 ? `${x}` : `0${x}`
        }
        let year = date.getFullYear();
        let mon = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minute = date.getMinutes();
        let second = date.getSeconds();
        if(format=='yyyy-MM-dd') {
            return `${year}-${formatNum(mon)}-${formatNum(day)}`;
        }else if (format=='yyyy-MM-dd hh:mm') {
            return `${year}-${formatNum(mon)}-${formatNum(day)} ${formatNum(hour)}:${formatNum(minute)}`;
        }else {
            return `${year}-${formatNum(mon)}-${formatNum(day)} ${formatNum(hour)}:${formatNum(minute)}:${formatNum(second)}`;
        }
    },
    mapList (dataList = [], key) {
        if(dataList.length <= 0) {
            return []
        }
        let obj = {};
        const recursion = (data) => {
            data.forEach((item) => {
                if (!obj[item.Value]) {
                    return;
                }else {
                    recursion(obj[item.Value], item.Value);
                }
                item.Children = obj[item.Value];
            });
            return data;
        }

        dataList.forEach((item) => {
            if (!obj[item.Parent]) {
                obj[item.Parent] = [];
            }
            obj[item.Parent].push(item);
        });
        return recursion(obj[key]);
    },
    // 时间戳转换成 年月日 格式
    timesFormatDate (time,fmt) {
        if(!time){
            return "";
        }else{
            let newDate = new Date(time);
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (newDate.getFullYear() + '').substr(4 - RegExp.$1.length));
            }
            var o = {
                'M+': newDate.getMonth() + 1,
                'd+': newDate.getDate(),
                'h+': newDate.getHours(),
                'm+': newDate.getMinutes(),
                's+': newDate.getSeconds()
            };
            for (var k in o) {
                if (new RegExp(`(${k})`).test(fmt)) {
                    var str = o[k] + '';
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
                }
            }
            return fmt;
        }
    },
    async dateIsAble(date, delayDay) {
        const params = {
            calyear: new Date().getFullYear(),
            calmonth: new Date().getMonth() + 1
        }
        const res = await httpService.GetDateConfig(params)
        if(res.success) {
            let count = 0;
            let lastDate;
            res.content.forEach((item, index) => {
                if(!item.isHoliday) {
                    count++;
                }
                if(count == delayDay) {
                    lastDate = res.content[index+1].calDate.split('T')[0].replace(/-/g,'/');
                    count++;
                }
            });
            return new Date(date) < new Date(lastDate);
        }
    }
}

export default publicFun
