import {SmartStorage} from 'smart-core-util'
import { httpService } from '../service/httpService'
import {getCookie} from 'tiny-cookie';
var enviroment = process.env.NODE_ENV; // 线上
export default class AppInitialization {
	static install() {
        this.setiDomain();
        this.urlParamToStorage();
		return new Promise(function (resolve) {
            let tenantId = getCookie('tenant_id') || SmartStorage.get("tenant_id");
            let container = getCookie("container") || SmartStorage.get("container")
            let params = {
                collection: "cfg-ievent-initialization",
                filter: {
                    tenantId: tenantId,
                },
                projection: {
                    _id: 0,
                },
            };
            if (['h5'].includes(container)) {
                params.filter.container = 'h5'
            }
            httpService.getsettings(params).then(response => {
                if(response && response.success && response.content) {
                    let config = response.content.config || {}
                    AppInitialization.setTheme(config.themeColors)
                    AppInitialization.setTitle(config.titleTxt)
                    let isGray = window.location.href.includes("pilot") ? config.grayUris : config.uris
                    AppInitialization.setUris(isGray)
                } else {
                    AppInitialization.setDefault()
                }
                resolve(response)
            }).catch(error => {
                AppInitialization.setTheme([
                    { "attribute": "--themeColor", "value": "#21BDB0" },
                    { "attribute": "--clickColor", "value": "#08998e" },
                    { "attribute": "--opcColor", "value": "#16797029" },
                    { "attribute": "--gradualColor", "value": "#0fa99d" },
                    { "attribute": "--shadowColor", "value": "#029e9266" },
                    { "attribute": "--lineColor", "value": "#027d71" }
                ])
                resolve(error)
            })
        })
    }
    static setDefault() {
        AppInitialization.setTheme([
            {"attribute": "--themeColor", "value": "#3875FE"},
            {"attribute": "--clickColor", "value": "#4F7CC4"},
            {"attribute": "--gradualColor", "value": "#4479F1"},
            {"attribute": "--shadowColor", "value": "#3875FECA"},
            {"attribute": "--lineColor", "value": "#3875FE"}
        ])
        AppInitialization.setTitle()
        let _uris = ['development'].includes(enviroment)  ?
            {
                signalr: "https://signalr-dev.eventworld.cn",
                gateway: "https://gateway-dev.eventworld.cn",
                "webapp-iaccount": "https://iaccount-crossevent-dev.eventworld.cn",
                "webapp-ievent": "https://ievent-crossevent-dev.eventworld.cn",
                "webapp-itraffic": "https://itraffic-dev.eventworld.cn",
                "webapp-igeneric": "https://igeneric-dev.eventworld.cn",
                "webapp-imeal": "https://imeal-crossevent-dev.eventworld.cn",
                "webapp-imealUnify": "https://imeal-crossevent-dev.eventworld.cn",
                "webapp-iwaimai": "https://iwaimai-crossevent-dev.eventworld.cn",
                "webapp-ivenue": "https://ivenue-crossevent-dev.eventworld.cn",
                "webapp-ijob": "https://ijob-dev.eventworld.cn",
                "webapp-icar": "https://icar-dev.eventworld.cn",
                "webapp-ihelper": "https://ihelper-dev.eventworld.cn"
            }
            :
            {
                signalr: "https://signalrx.smartmice.cn",
                gateway: "https://gateway-mice.smartmice.cn",
                "webapp-iaccount": "https://iaccount-mice.smartmice.cn",
                "webapp-ievent": "https://ievent-mice.smartmice.cn",
                "webapp-itraffic": "https://itraffic.smartmice.cn",
                "webapp-igeneric": "https://igeneric.smartmice.cn",
                "webapp-imeal": "https://imeal-mice.smartmice.cn",
                "webapp-imealUnify": "https://imeal-unify.smartmice.cn",
                "webapp-iwaimai": "https://iwaimai-mice.smartmice.cn",
                "webapp-icar": "https://icar-mice.smartmice.cn",
                "webapp-ihelper": "https://ihelper.smartmice.cn",
                "webapp-ijob": "https://ijob-mice.smartmice.cn",
                "webapp-ivenue": "https://ivenue-mice.smartmice.cn"
            }
        AppInitialization.setUris(_uris)
    }
    // 设置Uris
    static setUris(uris = {}) {
        SmartStorage.set("uris", uris)
        SmartStorage.set("Uris", { Uris: uris })
    }
    // 设置皮肤颜色
    static setTheme(colorList) {
        colorList.map(item => {
            document.body.style.setProperty(item.attribute, item.value)
            SmartStorage.set(item.attribute,item.value)
        })
    }
    // 设置title
    static setTitle(txt = "会引擎") {
        document.title = txt
    }

    // url 参数转 storage
    static urlParamToStorage() {
        let url = window.location.search.substring(1);
        if(url){
            let urlParams = url.split('&');
            urlParams.forEach(item => {
                let temp = item.split('=');
                SmartStorage.set(temp[0], temp[1]);
            })
        }
    }

    static setiDomain() {
        let domain = document.domain;
        const re = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;//正则表达式
        let isIP = re.test(domain);
        let isLocalhost = domain == "localhost";
        console.log('document domain:', domain);
        domain = (isIP || isLocalhost) ? domain : domain.substring(domain.indexOf('.') + 1);
        window.idomain = domain;
    }
}
