<template>
 <div :class='["sm-header", {"isFixed": isFixed}]'>
    <div class="sm-header-left">
        <slot name="smHleft">
            <div v-show="isBack">
              <span v-if="isWeixin" @click='smHleftEvent' class="back-title">返回</span>
              <span v-else class="left-click-area" @click='smHleftEvent'>
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-fanhui"></use>
                  </svg>
              </span>
            </div>
        </slot>
    </div>
    <div class="sm-header-title smTxtarea_hidden">
        <slot name="smHtitle">
            {{title}}
        </slot>
    </div>
    <div class="sm-header-right">
        <slot name="smHright">
            <span class="right-txt" @click='smHrightEvent' >
                {{rightTxt}}
            </span>
        </slot>
    </div>
 </div>
</template>

<script>
 export default {
    name:'sm-header',
    props:{
        leftTxt:{
            type: String,
            default: '返回'
        },
        title: {
            type: String,
            default: null
        },
        isBack: {
            type: Boolean,
            default: true
        },
        rightTxt: {
            type: String,
            default: null
        },
        rightColor: {
            type: String,
            default: '#13161B'
        },
        isFixed: {
            type: Boolean,
            default: true
        },
        smHleftEvent: {
            type: Function,
            default: function() {
                return console.log('点击header左侧')
            }
        },
        smHrightEvent: {
            type: Function,
            default: function() {
                return console.log('点击header右侧')
            }
        }
    },
   data () {
      return {
        isWeixin: false,
      }
   },
   created() {
     this.isWeixin =
         navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1;
   },
}
</script>

<style lang="scss" scoped>
@import './minxin.scss';
.isFixed{
    @include isFixedTop;
}
.sm-header{
    @include disFlex($justify: space-between);
    @include pad(0,0.15rem,0,.15rem);
    height: .44rem;
    background: #FFFFFF;
    z-index: 10;
    .sm-header-left{
        width: 18%;
        @include disFlex($justify: flex-start);
        height: 100%;
        font-size: .16rem;
        .left-click-area{
          width: 100%;
          height: 100%;
          line-height: .44rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          .icon{
            width: .2rem;
            height: .2rem;
          }
        }
    }
    .sm-header-title{
        @include disFlex;
        width: 64%;
        height: 100%;
        font-size: .17rem;
        font-weight: bold;
    }
    .sm-header-right{
        @include disFlex($justify: flex-end);
        width: 18%;
        height: 100%;
        font-size: .16rem;
        font-weight: 300;
        .right-txt{
          width: 100%;
          text-align: right;
        }
    }
  .back-title {
    color: #333;
    font-size: 0.16rem;
  }
}
</style>
