<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="this.$route.meta.keepAlive"></router-view>
      <!--这里是会被缓存的组件-->
    </keep-alive>
    <router-view v-if="!this.$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import inputScrollIntoView from "@/plugin/scrollIntoView.js"
export default {
  name: 'App',
  extends: inputScrollIntoView,
  components: {
  }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "PingFang SC Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  --themeColor: '#2D82F0';
  --clickColor: '#0391f0';
  --opcColor: '#1a6dca29';
  --gradualColor: '#4479f1';
  --shadowColor: '#1a6dca66';
  --lineColor: '#0081e2';
}
</style>
