import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        catchPages: [], // 需要缓存的页面
    },
    mutations: {
        //统一设置
        setValue(state, value) {
            state[value.key] = value.value;
        },
    },
    actions: {},
    modules: {}
})
