/**
 * 解决键盘遮挡输入框
 */
export default {
    data () {
        return {
        }
    },
    mounted() {
        window.addEventListener('resize',this.inputScrollIntoView)
    },
    methods: {
        inputScrollIntoView() {
            if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
                document.activeElement.scrollIntoViewIfNeeded()
                // 处理activeElement滚动到可视区域，有空白的情况
                window.scrollTo(0,0)
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize',this.inputScrollIntoView)
    }
}
